<template>
  <div>
    <div v-if="['custom_month', 'custom_day'].includes(productUsageOrder.user_business_type_slug)">
      <show-agent-monthly-bill-custom-order :product-usage-order="productUsageOrder" />
    </div>
    <div v-else-if="productUsageOrder.user_business_type_slug === 'traffic_share'">
      <show-agent-monthly-bill-traffic-share-order :product-usage-order="productUsageOrder" />
    </div>
  </div>
</template>

<script>
import { findAgentProductUsageOrder } from '@/api/agent_product_usage_order'

export default {
  name: 'ShowAgentMonthlyBillCardUse',
  components: {
    ShowAgentMonthlyBillCustomOrder: () => import('@/views/agent_monthly_bills/card_use/agency_self/show/Custom'),
    ShowAgentMonthlyBillTrafficShareOrder: () => import('@/views/agent_monthly_bills/card_use/agency_self/show/TrafficShare')
  },
  data() {
    return {
      productUsageOrder: {}
    }
  },
  computed: {
    productUsageOrderId() {
      return parseInt(this.$route.params.card_use_id)
    },

    billId() {
      return parseInt(this.$route.query.bill_id)
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      findAgentProductUsageOrder(this.productUsageOrderId).then((res) => {
        if (res.code === 0) {
          this.productUsageOrder = res.data
          this.$store.dispatch('ConcatDynamicBreadCrumbs', [
            { redirect: `/monthly_bills`, meta: { title: '月账单' }},
            { redirect: `/monthly_bills/${this.billId}`, meta: { title: this.productUsageOrder.month }},
            { redirect: ``, meta: { title: this.productUsageOrder.agents_product_name }}
          ]
          ).then(() => {})
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.bread-crumb {
  margin-bottom: 20px;
}
</style>
