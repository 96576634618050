import request from '@/utils/request'

// 查询客户流量使用详情
export function findAgentProductUsageOrder(id) {
  return request({
    url: `/agent_product_usage_orders/${id}`,
    method: 'get'
  })
}

// 查询客户流量使用详情卡号列表
export function findSimCardOrders(id, params) {
  return request({
    url: `/agent_product_usage_orders/${id}/sim_card_orders`,
    method: 'get',
    params: params
  })
}

// 导出客户流量使用详情卡号列表
export function exportSimCardOrders(id, data) {
  return request({
    url: `/agent_product_usage_orders/${id}/sim_card_orders/export`,
    method: 'post',
    data
  })
}
